import { Container } from 'react-bootstrap';
import './ComingSoon.css';

const ComingSoon = () => {
  return (
    <div className='coming-soon-page'>
        <Container>
            <div className="coming-soon-parent">
                <div class="content-div">
                    <h1>Coming Soon</h1>
                    <p>Stay tuned for something <span class="highlight">exciting</span>!</p>
                </div>
            </div>
        </Container>
    </div>
  )
}

export default ComingSoon