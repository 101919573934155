import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css";
import "transition-style";
import "transition-style";
import React, { useEffect } from "react";
import "animate.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Routes, Route } from "react-router-dom";
import { Home } from "./Components/Home/Home";
import { Header } from "./Components/CommonComponents/Header/Header.jsx";
import { ScrollToTop } from "./Components/CommonComponents/ScrollToTop.jsx";
import { ContactUs } from "./Components/ContactUs/ContactUs.jsx";
import { AboutUs } from "./Components/AboutUs/AboutUs.jsx";
import { Payment } from "./Components/Payment/Payment.jsx";
import { PricingPlan } from "./Components/PricingPlan/PricingPlan.jsx";
import { Ourtrades } from "./Components/OurTrades/Ourtrades.jsx";
import { Services } from "./Components/Services/Services.jsx";
import { CostEstimating } from "./Services-Pages/CostEstimating/CostEstimating.jsx";
import { CostructionTakeOff } from "./Services-Pages/ConstructionTakeOff/CostructionTakeOff.jsx";
import { MaterialTakeOff } from "./Services-Pages/ConstructionTakeOff/MaterialTakeOff.jsx";
import { QuantitiesTakeOff } from "./Services-Pages/ConstructionTakeOff/QuantitiesTakeOff.jsx";
import { CPMScheduling } from "./Services-Pages/CPMScheduling/CPMScheduling.jsx";
import { Planning } from "./Services-Pages/Planning/Planning.jsx";
import { Footer } from "./Components/CommonComponents/Footer/Footer.jsx";
import { GeneralRequirements } from "./Components/Trades-Pages/GeneralRequirements.jsx";
import { SiteWork } from "./Components/Trades-Pages/SiteWork.jsx";
import { Concrete } from "./Components/Trades-Pages/Concrete.jsx";
import { Metals } from "./Components/Trades-Pages/Metals.jsx";
import { DryWall } from "./Components/Trades-Pages/DryWall.jsx";
import { MEP } from "./Components/Trades-Pages/MEP.jsx";
import { HVAC } from "./Components/Trades-Pages/HVAC.jsx";
import NotFound from "./Components/NotFound/NotFound.jsx";
import ComingSoon from "./Components/ComingSoon/ComingSoon.jsx";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  useEffect(() => {
    const screenWidth = window.innerWidth;
    console.log(screenWidth);

    AOS.init({
      duration: 1000, // duration of animation in milliseconds
    });
  }, []);

  return (
    <div>
      <Header />
      <ScrollToTop />
      <ToastContainer />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact-us/" element={<ContactUs />} />
        <Route path="/about-us/" element={<AboutUs />} />
        <Route path="/services/" element={<Services />} />
        <Route path="/our-trades/" element={<Ourtrades />} />
        <Route path="/estimating-fee/" element={<AboutUs />} />
        <Route path="/payment/" element={<Payment />} />
        <Route path="/Pricing-Plan/" element={<PricingPlan />} />
        <Route
          path="/construction-takeoff-services/"
          element={<CostructionTakeOff />}
         />
        <Route
          path="/cost-estimating-services/"
          element={<CostEstimating />}
         />

        <Route
          path="/material-takeoff-services/"
          element={<MaterialTakeOff />}
         />

        <Route
          path="/quantity-takeoff-services/"
          element={<QuantitiesTakeOff />}
         />
        <Route path="/planning/" element={<Planning />} />
        <Route path="/cpm-scheduling/" element={<CPMScheduling />} />
        <Route
          path="/general-requirements/"
          element={<GeneralRequirements />}
         />
        <Route
          path="/sitework-estimating-services/"
          element={<SiteWork />}
         />
        <Route
          path="/concrete-estimating-services/"
          element={<Concrete />}
         />
        <Route path="/metals-estimating-services/" element={<Metals />} />
        <Route
          path="/drywall-estimating-services/"
          element={<DryWall />}
         />
        <Route path="/mep-estimating-services/" element={<MEP />} />
        <Route path="/hvac-estimating-services/" element={<HVAC />} />
        <Route path="/lumber-takeoff-services" element={<ComingSoon />} />
        <Route path="/wood-plastic-estimating-services" element={<ComingSoon />} />
        <Route path="/thermal-moisture-protection-estimating-services" element={<ComingSoon />} />
        <Route path="/roofing-estimating-services" element={<ComingSoon />} />
        <Route path="/insulation-estimating-services" element={<ComingSoon />} />
        <Route path="/framing-estimating-services" element={<ComingSoon />} />
        <Route path="/interior-exterior-finishes" element={<ComingSoon />} />
        <Route path="/flooring-estimating-services" element={<ComingSoon />} />
        <Route path="/furnishing-estimating-services" element={<ComingSoon />} />
        <Route path="/masonry-estimating-services" element={<ComingSoon />} />
        <Route path="/landscaping-estimating-services" element={<ComingSoon />} />
        <Route path="/rebar-estimating-services" element={<ComingSoon />} />
        <Route path="/equipment-estimating-services" element={<ComingSoon />} />
        <Route path="/conveying-system-estimating-services" element={<ComingSoon />} />
        <Route path="/commercial-estimating-services" element={<ComingSoon />} />
        <Route path="/industrial-estimating-services" element={<ComingSoon />} />
        <Route path="/residential-estimating-services" element={<ComingSoon />} />
        <Route path="/mechanical-estimating-services" element={<ComingSoon />} />
        <Route path="/electrical-estimating-services" element={<ComingSoon />} />
        <Route path="/plumbing-estimating-services" element={<ComingSoon />} />
        <Route path="/gutter-estimating-services" element={<ComingSoon />} />
        <Route path="/special-construction-estimating-services" element={<ComingSoon />} />
        <Route path="/plumbing-estimating-services" element={<ComingSoon />} />

        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
