import { Container } from 'react-bootstrap';
import './NotFound.css';

const NotFound = () => {
  return (
    <div className="not-found-page">
        <Container>
            <div className="not-found-parent">
                <p>404! Page not found</p>
            </div>
        </Container>
    </div>
  )
}

export default NotFound