import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { Banner } from "../CommonComponents/Banner";
import "./ContactUs.css";

export const ContactUs = () => {
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth >= 1024);
  const [loading, setLoading] = useState(false); // Loading state added
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true); // Set loading to true when submitting
    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/front/contact/submit`, data);
      if (response.data.success) {
        reset();
        toast.success("Message sent successfully!");
      } else {
        toast.error("Failed to send message. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred while sending the message.");
    } finally {
      setLoading(false); // Set loading to false after response
    }
  };

  return (
    <div>
      <div
        data-aos={isLargeScreen ? "fade-down" : ""}
        className="ContectHeroSection"
      >
        <div className="text-center border-1 p-3 rounded">
          <h3>Contact Us</h3>
          <div className="formContainer">
            <form className="w-100" onSubmit={handleSubmit(onSubmit)}>
              <div className="input-div">
                <input
                  className="inpitFields"
                  placeholder="Your Name"
                  type="text"
                  {...register("name", { required: "Name is required" })}
                />
                {errors.name && (
                  <span className="text-danger d-block text-start">{errors.name.message}</span>
                )}
              </div>
              <div className="input-div">
                <input
                  className="inpitFields"
                  placeholder="Your Email"
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Invalid email address",
                    },
                  })}
                />
                {errors.email && (
                  <span className="text-danger d-block text-start">{errors.email.message}</span>
                )}
              </div>

              <div className="input-div">
                <input
                  className="inpitFields"
                  placeholder="Your Contact"
                  type="tel"
                  {...register("phone", { required: "Phone number is required" })}
                />
                {errors.phone && <span className="text-danger d-block text-start">{errors.phone.message}</span>}
              </div>

              <div className="input-div">
                <textarea
                  className="textAreaFields"
                  rows={3}
                  placeholder="Message"
                  {...register("message", { required: "Message is required" })}
                ></textarea>
                {errors.message && (
                  <span className="text-danger d-block text-start">{errors.message.message}</span>
                )}
              </div>

              <button
                className="submitBtn"
                type="submit"
                disabled={loading} // Disable the button when loading
              >
                {loading ? "Sending..." : "Send"} {/* Change button text based on loading state */}
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-4 mb-4">
        <Banner />
      </div>
      <div className="container-md">
        <div className="row m-0 w-100 justify-content-center">
          <div
            data-aos={isLargeScreen ? "fade-right" : ""}
            className="col-lg-5 col-md-6 col-12"
          >
            <h3>Contacts</h3>
            <p>
              Our phone and email are on our site. Contact UK Estimators for
              construction estimates and more. Send plans for estimation
              services. Save money, reach out today!
            </p>
            <a
              className="text-decoration-none text-dark d-inline-block mt-2 mb-2"
              href=""
            >
              <img src={require("../../Images/Location.png")} alt="" /> &nbsp;
              247 Metropolitan Ave, Brooklyn, NY 11211, USA
            </a>

            <a
              className="text-decoration-none text-dark d-inline-block mt-2 mb-2"
              href=""
            >
              <img src={require("../../Images/Call.png")} alt="" /> &nbsp; +1
              917 300 1079
            </a>
            <br />
            <a
              className="text-decoration-none text-dark d-inline-block mt-2 mb-2"
              href="mailto:muhammad@mfbzone.com"
            >
              <img src={require("../../Images/Mail.png")} alt="" /> &nbsp;
              muhammad@mfbzone.com
            </a>
          </div>
          <div
            data-aos={isLargeScreen ? "fade-left" : ""}
            className="col-lg-7 col-md-6 col-12"
          >
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d13617.382621019875!2d74.27661825!3d31.43214975!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1719488405481!5m2!1sen!2s"
                style={{ width: "100%", height: "270px" }}
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
